import { PersonWidget } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest } from 'store/store-tools';

export const initialState: {
    personWidgets: ApiRequest<PersonWidget[]>,
    addPersonWidget: ApiRequest,
    deletePersonWidget: ApiRequest,
} = {
    personWidgets: initialApiRequest(),
    addPersonWidget: initialApiRequest(),
    deletePersonWidget: initialApiRequest(),
} as const;

export type WidgetState = typeof initialState;

export const WidgetReducer: Reducer<WidgetState> = (state = initialState, action) => {
    switch (action.type) {
        case '@WIDGET/GET_PERSON_WIDGETS_FETCH_REQUEST':
            return {
                ...state,
                personWidgets: {
                    ...state.personWidgets,
                    state: 'isFetching',
                },
            } satisfies WidgetState;
        case '@WIDGET/GET_PERSON_WIDGETS_FETCH_SUCESS':
            return {
                ...state,
                personWidgets: {
                    ...state.personWidgets,
                    state: 'valid',
                    data: action.payload,
                },
            } satisfies WidgetState;
        case '@WIDGET/GET_PERSON_WIDGETS_FETCH_FAILURE':
            return {
                ...state,
                personWidgets: {
                    ...state.personWidgets,
                    state: 'error',
                },
            } satisfies WidgetState;

        case '@WIDGET/ADD_PERSON_WIDGETS_FETCH_REQUEST':
            return {
                ...state,
                addPersonWidget: {
                    ...state.addPersonWidget,
                    state: 'isFetching',
                },
            } satisfies WidgetState;
        case '@WIDGET/ADD_PERSON_WIDGETS_FETCH_SUCESS':
            return {
                ...state,
                addPersonWidget: {
                    ...state.addPersonWidget,
                    state: 'valid',
                },
                personWidgets: {
                    ...state.personWidgets,
                    state: 'outdated',
                },
            } satisfies WidgetState;
        case '@WIDGET/ADD_PERSON_WIDGETS_FETCH_FAILURE':
            return {
                ...state,
                addPersonWidget: {
                    ...state.addPersonWidget,
                    state: 'error',
                },
            } satisfies WidgetState;

        case '@WIDGET/DELETE_PERSON_WIDGETS_FETCH_REQUEST':
            return {
                ...state,
                deletePersonWidget: {
                    ...state.deletePersonWidget,
                    state: 'isFetching',
                },
            } satisfies WidgetState;
        case '@WIDGET/DELETE_PERSON_WIDGETS_FETCH_SUCESS':
            return {
                ...state,
                deletePersonWidget: {
                    ...state.deletePersonWidget,
                    state: 'valid',
                },
                personWidgets: {
                    ...state.personWidgets,
                    state: 'outdated',
                },
            } satisfies WidgetState;
        case '@WIDGET/DELETE_PERSON_WIDGETS_FETCH_FAILURE':
            return {
                ...state,
                deletePersonWidget: {
                    ...state.deletePersonWidget,
                    state: 'error',
                },
            } satisfies WidgetState;

        default:
            return state;
    }
};