import 'react-app-polyfill/ie11';
import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './components/App';
import { TechnicalLogger } from 'core/services/common/logging/logger';
import { loadSGConnect } from 'core/services/common/sgConnect';
import { configureStore } from 'store/store';
import { configureI18n } from 'config/i18n';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { MatomoWidget } from 'components/common/matomo/MatomoWidget';
import { messages, locales } from 'virtual-vite-i18n-resources';
import { configureRum } from 'config/rum';

import './polyfill';
import 'pixeden-stroke-7-icon';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const startup = async () => {
    configureRum();
    
    const container = document.getElementById('root');
    if (!container) {
        console.log('Can\'t find root container. App can\'t start');
        return;
    }

    loadSGConnect();

    const i18n = await configureI18n(messages, locales);

    const store = configureStore({
        logger: new TechnicalLogger(),
        translate: i18n.t,
    }, []);

    const root = createRoot(container);
    root.render(<RootApp store={store} />);
};

interface IRootAppProps {
    store: ReturnType<typeof configureStore>
}

const RootApp: FC<IRootAppProps> = ({ store }) => {
    return <Provider store={store}>
        <Router>
            <MatomoWidget />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeButton={false}
                icon={false}
                newestOnTop
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                stacked
            />
            <App />
        </Router>
    </Provider>;
};

startup();
